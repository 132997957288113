export const defaultRoles = {
    werwolf: "Werwolf",
    dorfbewohner: "Dorfbewohner",
    seherin: "Seherin",
    hexe: "Hexe",
    jaeger: "Jäger",
    armor: "Armor",
    heiler: "Heiler",
    prinz: "Prinz",

    AlterMann: "Alter Mann",
    depp: "Dorfdepp",
    drache: "Drache (Joker)",
    bursche: "Harter Bursche",
    lykanthrophin: "Lykanthrophin",
    post: "Postbote (Joker)",
    priest: "Priester",
    lehrling: "Seherlehrling",
    traumwolf: "Traumwolf (Joker)",
    whiteWolf: "Weißer Wolf",
    wolfsjunges: "Wolfsjunges",
    zahnarzt: "Zahnarzt",
};

export const availableIcons = [
    "fa-image",
    "fa-bell",
    "fa-comment",
    "fa-star",
    "fa-heart",
    "fa-eye",
    "fa-eye-slash",
    "fa-comments",
    "fa-compass",
    "fa-handshake",
    "fa-snowflake",
    "fa-sun",
    "fa-clock",
    "fa-flag",
    "fa-flag-checkered",
    "fa-hand-point-up",
    "fa-moon",
    "fa-lightbulb",
    "fa-map",
    "fa-life-ring",
    "fa-hourglass-half",
    "fa-gem",
    "fa-smile",
    "fa-surprise",
    "fa-sad-cry",
    "fa-meh",
    "fa-kiss-wink-heart",
    "fa-grin-stars",
    "fa-grin-hearts",
    "fa-frown",
    "fa-flushed",
    "fa-dizzy",
    "fa-angry",
    "fa-plane",
    "fa-car",
    "fa-rocket",
    "fa-ship",
    "fa-bicycle",
    "fa-key",
    "fa-fire",
    "fa-tree",
    "fa-leaf",
    "fa-cloud",
    "fa-water",
    "fa-magnet",
    "fa-gears",
    "fa-flask",
    "fa-ticket",
    "fa-palette",
    "fa-feather",
    "fa-pen",
    "fa-anchor",
    "fa-atom",
    "fa-sign",
    "fa-cross",
    "fa-question",
    "fa-shield",
    "fa-wrench",
    "fa-hammer",
    "fa-robot",
    "fa-crown",
    "fa-box",
    "fa-chair",
    "fa-joint",
    "fa-ruler",
    "fa-scroll",
    "fa-coins",
    "fa-puzzle-piece",
    "fa-dice",
    "fa-lock",
    "fa-unlock",
    "fa-glasses",
    "fa-cookie-bite",
    "fa-wine-glass",
    "fa-vial",
    "fa-umbrella"
]